import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './styles/App.css';

const discordColors = {
    blue: '#7289DA',
    purple: '#9B59B6',
    red: '#E74C3C',
    green: '#2ECC71',
    yellow: '#F1C40F',
    grey: '#95A5A6',
    white: '#FFFFFF',
    black: '#000000',
};

const App = () => {
    const [user, setUser] = useState(null);
    const [servers, setServers] = useState([]);
    const [channels, setChannels] = useState([]);
    const [selectedServerId, setSelectedServerId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentEmbedIndex, setCurrentEmbedIndex] = useState(null);
    const [newEmbed, setNewEmbed] = useState(initialEmbedState());
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [step, setStep] = useState(1);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    function initialEmbedState() {
        return {
            author: { name: '', iconURL: '' },
            title: '',
            description: '',
            url: '',
            imageURL: '',
            thumbnailURL: '',
            footer: { text: '', iconURL: '' },
            timestamp: '',
            color: discordColors.blue,
            fields: [],
        };
    }

    // Definition von fetchMessages mit useCallback
    const fetchMessages = useCallback((serverId) => {
        axios.get(`https://embedbackend.onrender.com/api/messages/${serverId}`, { withCredentials: true })
            .then(response => {
                setMessages(response.data); // Nachrichten setzen
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Nachrichten:', error);
            });
    }, []); // Abhängigkeiten hier anpassen

    // Authentifizierung und Serverabruf beim Laden der App
    useEffect(() => {
        axios.get('https://embedbackend.onrender.com/api/auth/status', { withCredentials: true })
            .then(response => setUser(response.data))
            .catch(() => setUser(null));

        fetchServers(); // Server beim Laden der App abrufen
    }, []);

    // Nachrichten für den ausgewählten Server abrufen
    useEffect(() => {
        if (selectedServerId) {
            fetchMessages(selectedServerId);
        }
    }, [selectedServerId, fetchMessages]); // fetchMessages in die Abhängigkeiten aufnehmen

    const login = () => {
        window.location.href = 'https://embedbackend.onrender.com/api/auth/discord'; // Protokoll hinzufügen
    };

    const fetchServers = () => {
        axios.get('https://embedbackend.onrender.com/api/bot-servers')
            .then(response => setServers(response.data))
            .catch(error => console.error('Error fetching servers:', error));
    };

    const fetchChannels = (serverId) => {
        setSelectedServerId(serverId); // Server-ID speichern
        axios.get(`https://embedbackend.onrender.com/api/guilds/${serverId}/channels`)
            .then(response => setChannels(response.data))
            .catch(error => console.error('Error fetching channels:', error));
    };

    const createNewMessage = () => {
        if (unsavedChanges) {
            if (!window.confirm('You have unsaved changes. Do you want to continue without saving?')) {
                return;
            }
        }
        const newMessage = {
            content: '',
            embeds: [],
            channel: '',
            serverId: selectedServerId,
            messageId: null // Nachricht wurde noch nicht gesendet
        };
        setMessages([...messages, newMessage]);
        setSelectedMessage(newMessage);
        setUnsavedChanges(false);
        setStep(1);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredMessages = messages.filter(message => message.content.includes(searchTerm));

    const openModal = (index) => {
        setCurrentEmbedIndex(index);
        if (index !== null) {
            setNewEmbed({ ...selectedMessage.embeds[index] });
        } else {
            setNewEmbed(initialEmbedState());
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setNewEmbed(initialEmbedState());
        setCurrentEmbedIndex(null);
    };

    const saveEmbed = () => {
        if (!validateForm()) return;
        const updatedMessage = { ...selectedMessage };
        if (currentEmbedIndex !== null) {
            updatedMessage.embeds[currentEmbedIndex] = newEmbed;
        } else {
            updatedMessage.embeds.push(newEmbed);
        }
        setSelectedMessage(updatedMessage);
        closeModal();
        setUnsavedChanges(true);
    };

   const removeEmbed = (index) => {
       const updatedMessage = { ...selectedMessage };
       updatedMessage.embeds.splice(index, 1);
       setSelectedMessage(updatedMessage);
       setUnsavedChanges(true);
   };

   const handleSelectMessage = (message) => {
       if (unsavedChanges) {
           if (!window.confirm('You have unsaved changes. Do you want to continue without saving?')) {
               return;
           }
       }
       setSelectedMessage(message); // Nachricht auswählen
       setUnsavedChanges(false);
   };

   const updateMessage = async () => {
       if (!selectedMessage.messageId) {
           console.error('Cannot update a message that has not been sent.');
           return;
       }

       try {
           await axios.put('https://embedbackend.onrender.com/api/update-message', {
               messageId: selectedMessage.messageId,
               messageData: selectedMessage,
               user,
           });
           setUnsavedChanges(false);
       } catch (error) {
           console.error('Error updating message:', error.message);
       }
   };

   const sendNewMessage = async () => {
       if (!selectedMessage || !selectedMessage.channel || !selectedMessage.content.trim()) {
           console.error('Message content or channel is missing.');
           return;
       }

       try {
           const response = await axios.post('https://embedbackend.onrender.com/api/send-message', {
               channelId: selectedMessage.channel,
               messageData: { ...selectedMessage, serverId: selectedServerId },
               user,
           });

           const messageId = response.data.messageId;
           if (messageId) {
               const updatedMessage = { ...selectedMessage, messageId };
               setSelectedMessage(updatedMessage);
               await saveMessage(updatedMessage);
           }

           setUnsavedChanges(false);
       } catch (error) {
           console.error('Error sending new message:', error.message);
       }
   };

   const saveMessage = async (message) => {
       try {
           await axios.post('https://embedbackend.onrender.com/api/servers/save-message', { message });
           console.log('Message saved successfully');
       } catch (error) {
           console.error('Error saving message:', error);
       }
   };

   // Funktion zum Hinzufügen eines neuen Feldes zu einem Embed
   const addFieldToEmbed = () => {
       setNewEmbed({
           ...newEmbed,
           fields: [...newEmbed.fields, { name: '', value: '', inline: false }]
       });
   };

   const formatText = (format) => {
       const textarea = document.getElementById("embedDescription");
       const start = textarea.selectionStart;
       const end = textarea.selectionEnd;
       const selectedText = textarea.value.substring(start, end);
       const before = textarea.value.substring(0, start);
       const after = textarea.value.substring(end, textarea.value.length);

       const formatTags = {
           bold: '**',
           italic: '*',
           underline: '__',
           strikethrough: '~~'
       };

       textarea.value = `${before}${formatTags[format]}${selectedText}${formatTags[format]}${after}`;
       setNewEmbed({ ...newEmbed, description: textarea.value });
   };

   // Form Validation for Required Fields
   const validateForm = () => {
       if (!newEmbed.title) {
           alert('Embed Title is required.');
           return false;
       }
       if (!newEmbed.description) {
           alert('Embed Description is required.');
           return false;
       }
       return true;
   };

   // Funktion zum Entfernen eines Feldes aus einem Embed
   const removeEmbedField = (index) => {
       const updatedFields = [...newEmbed.fields];
       updatedFields.splice(index, 1);
       setNewEmbed({ ...newEmbed, fields: updatedFields });
   };

   useEffect(() => {
       axios.get('http://45.147.7.228:2005/api/auth/status', { withCredentials: true })
           .then(response => setUser(response.data))
           .catch(() => setUser(null));

       fetchServers(); // Server beim Laden der App abrufen
   }, []);


        const renderStep = () => {
            if (step === 1) {
                return (
                    <div className="embed-editor">
                        <h3>Edit Message</h3>
                        <label>Message Content</label>
                        <br></br>
                        <br></br>
                        <textarea
                            className="message-input"
                            placeholder="Message Content"
                            value={selectedMessage?.content || ''}
                            onChange={(e) => {
                                setSelectedMessage({ ...selectedMessage, content: e.target.value });
                                setUnsavedChanges(true);
                            }}
                            style={{ height: '100px' }} // Höhe des Textbereichs anpassen
                        />
                        {/* Nur bei neuen Nachrichten den Channel auswählen */}
                        {!selectedMessage?.messageId && (
                            <>
                                <h4>Channel</h4>
                                <select
                                    className="channel-select"
                                    value={selectedMessage?.channel || ''}
                                    onChange={(e) => setSelectedMessage({ ...selectedMessage, channel: e.target.value })}
                                    style={{ marginBottom: '10px' }} // Abstand nach unten
                                >
                                    <option value="">Select a Channel</option>
                                    {channels.map(channel => (
                                        <option key={channel.id} value={channel.id}>
                                            {channel.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                        <h4>Embeds</h4>
                        {selectedMessage?.embeds.map((embed, index) => (
                            <div key={index} className="embed-list-item">
                                <h5>{embed.title || 'Untitled Embed'}</h5>
                                <button onClick={() => openModal(index)}>Edit</button>
                                <button onClick={() => removeEmbed(index)}>Remove</button>
                            </div>
                        ))}
                        <button onClick={() => openModal(null)}>Add New Embed</button>
    
                        {/* Update Message Button */}
                        {selectedMessage?.messageId && (
                            <button className="send-btn" onClick={updateMessage}>Update Message</button>
                        )}
                        {/* Send Message Button */}
                        {!selectedMessage?.messageId && (
                            <button className="send-btn" onClick={sendNewMessage}>Send Message</button>
                        )}
                    </div>
                );
            }
            return null;
        };
    
        return (
            <div className="App" >
    
                {!user ? (
                    <div className="login-container">
                        <button className="login-btn" onClick={login}>Login with Discord</button>
                    </div>
                ) : (
                    <div className="dashboard">
                        <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
                            <button className="toggle-btn" onClick={toggleSidebar}>Toggle</button>
                            <div className="button-group">
                                <button className="nav-button active">Servers</button>
                            </div>
                            <ul>
                                {servers.map(server => (
                                    <li
                                        key={server.id}
                                        onClick={() => { fetchMessages(server.id); fetchChannels(server.id); }}
                                        className={selectedServerId === server.id ? 'active-server' : ''}
                                    >
                                        <img src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png`} className="server-icon" alt="" />
                                        <span>{server.name}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
    
                        <div className="message-list">
                            <div className="message-controls">
                                <button className="fetch-btn" onClick={() => fetchMessages(selectedServerId)}>Fetch Messages</button>
                                <button className="create-btn" onClick={createNewMessage}>Create New Message</button>
                            </div>
                            <input
                                type="text"
                                placeholder="Search Messages"
                                value={searchTerm}
                                onChange={handleSearch}
                                className="search-bar"
                            />
                            <ul>
                                {filteredMessages.length === 0 && <p>No messages found</p>}
                                {filteredMessages.map((message, index) => (
                                    <li key={index} onClick={() => handleSelectMessage(message)}>
                                        Message {index + 1}: {message.content}
                                    </li>
                                ))}
                            </ul>
                        </div>
    
                        <div className="editor-panel">
                            <div className="editor-header">
                                <h2>Message Editor</h2>
                            </div>
                            <div className="editor-content">
                                {selectedMessage ? renderStep() : <p>Select a message or create a new one.</p>}
                            </div>
                        </div>
    
                        <div className="preview-panel">
                            <h3>Message Preview</h3>
                            <div className="preview-message">
                                <p>{selectedMessage?.content || 'No message content'}</p>
                                {selectedMessage?.embeds.map((embed, index) => (
                                    <div key={index} className="embed-preview" style={{ borderLeft: `4px solid ${embed.color}` }}>
                                        
                                        {/* Autor-Section */}
                                        {embed.author && (
                                            <div className="embed-author">
                                                {embed.author.iconURL && <img src={embed.author.iconURL} alt="" />}
                                                <span className="embed-author-name">{embed.author.name}</span>
                                            </div>
                                        )}
                                        {/* Thumbnail */}
                                        {embed.thumbnailURL && (
                                            <img src={embed.thumbnailURL} className="embed-thumbnail" alt="" />
                                        )}
                                        {/* Titel mit Link */}
                                        {embed.title && (
                                            <h4>
                                                {embed.url ? (
                                                    <a href={embed.url} target="_blank" rel="noopener noreferrer">
                                                        {embed.title}
                                                    </a>
                                                ) : (
                                                    embed.title
                                                )}
                                            </h4>
                                        )}



                                        {/* Beschreibung */}
                                        {embed.description && <p>{embed.description}</p>}

                                        {/* Embed-Felder */}
                                        {embed.fields && embed.fields.map((field, fieldIndex) => (
                                            <div key={fieldIndex} className="embed-field">
                                                <span className="embed-field-name">{field.name}:</span> {field.value}
                                            </div>
                                        ))}

                                        {/* Großes Bild */}
                                        {embed.imageURL && (
                                            <img src={embed.imageURL} className="embed-image" alt="" />
                                        )}

                                        {/* Footer-Section */}
                                        {embed.footer && (
                                            <div className="embed-footer">
                                                {embed.footer.iconURL && <img src={embed.footer.iconURL} alt="" />}
                                                <span className="embed-footer-text">{embed.footer.text}</span>
                                                {embed.timestamp && (
                                                    <span className="embed-footer-timestamp">
                                                        {new Date(embed.timestamp).toLocaleString()}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        </div>
                )}
    
                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h4>{currentEmbedIndex !== null ? 'Edit Embed' : 'Add New Embed'}</h4>
                        <label>Title </label>
                        <span>({newEmbed.title.length}/256)</span>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Title"
                            value={newEmbed.title}
                            onChange={(e) => setNewEmbed({ ...newEmbed, title: e.target.value })}
                        />
                        <br></br>
                        <label>Title URL</label>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Title URL"
                            value={newEmbed.url}
                            onChange={(e) => setNewEmbed({ ...newEmbed, url: e.target.value })}
                        />
                        <br></br>
                        <label>Description </label>
                        <span>({newEmbed.description.length}/2048)</span>
                        <button onClick={() => formatText('bold')} className='formattings'>𝐁</button>
                                <button onClick={() => formatText('italic')} className='formattings'>𝘐</button>
                                <button onClick={() => formatText('underline')} className='formattings'>U̲</button>
                                <button onClick={() => formatText('strikethrough')} className='formattings'> ̶S̶</button>
                        <br></br>
                        <textarea
                            className='description'
                            placeholder="Description"
                            id="embedDescription"
                            value={newEmbed.description}
                            onChange={(e) => setNewEmbed({ ...newEmbed, description: e.target.value })}
                        />
                        <br></br>
                        <label>Color</label>
                        <br></br>
                        <input
                            type="color"
                            value={newEmbed.color}
                            onChange={(e) => setNewEmbed({ ...newEmbed, color: e.target.value })}
                        />
                        <br></br>
                        <label>Author Name</label>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Author Name"
                            value={newEmbed.author.name}
                            onChange={(e) => setNewEmbed({ ...newEmbed, author: { ...newEmbed.author, name: e.target.value } })}
                        />
                        <br></br>
                        <label>Author Icon URL</label>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Author Icon URL"
                            value={newEmbed.author.iconURL}
                            onChange={(e) => setNewEmbed({ ...newEmbed, author: { ...newEmbed.author, iconURL: e.target.value } })}
                        />
                        <br></br>
                        <label>Footer Text</label>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Footer Text"
                            value={newEmbed.footer.text}
                            onChange={(e) => setNewEmbed({ ...newEmbed, footer: { ...newEmbed.footer, text: e.target.value } })}
                        />
                        <br></br>
                        <label>Footer Icon URL</label>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Footer Icon URL"
                            value={newEmbed.footer.iconURL}
                            onChange={(e) => setNewEmbed({ ...newEmbed, footer: { ...newEmbed.footer, iconURL: e.target.value } })}
                        />
                        <br></br>
                        <label>Thumbnail URL</label>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Thumbnail URL"
                            value={newEmbed.thumbnailURL}
                            onChange={(e) => setNewEmbed({ ...newEmbed, thumbnailURL: e.target.value })}
                        />
                        <br></br>
                        <label>Image URL</label>
                        <br></br>
                        <input
                            type="text"
                            placeholder="Image URL"
                            value={newEmbed.imageURL}
                            onChange={(e) => setNewEmbed({ ...newEmbed, imageURL: e.target.value })}
                        />
                        <br></br>
                        <label>Timestamp</label>
                        <br></br>
                        <input
                            type="datetime-local"
                            placeholder="Timestamp"
                            value={newEmbed.timestamp}
                            onChange={(e) => setNewEmbed({ ...newEmbed, timestamp: e.target.value })}
                        />
                        <div>
                            <h5>Fields</h5>
                            {newEmbed.fields.map((field, index) => (
                                <div key={index}>
                                    <label>Field Name:</label>
                                    <span>{field.name.length}/256</span>
                                    <br></br>
                                    <input
                                        type="text"
                                        placeholder="Field Name"
                                        value={field.name}
                                        onChange={(e) => {
                                            const updatedFields = [...newEmbed.fields];
                                            updatedFields[index].name = e.target.value;
                                            setNewEmbed({ ...newEmbed, fields: updatedFields });
                                        }}
                                    />
                                    <br></br>
                                    <label>Field Value:</label>
                                    <span>{field.value.length}/1024</span>
                                    <br></br>
                                    <input
                                        type="text"
                                        id='fieldValue'
                                        placeholder="Field Value"
                                        value={field.value}
                                        onChange={(e) => {
                                            const updatedFields = [...newEmbed.fields];
                                            updatedFields[index].value = e.target.value;
                                            setNewEmbed({ ...newEmbed, fields: updatedFields });
                                        }}
                                    />
                                    <button onClick={() => removeEmbedField(index)}>Remove Field</button>
                                </div>
                            ))}
                            <button onClick={addFieldToEmbed} class="addfield">Add Field</button>
                        </div>
                        <button onClick={saveEmbed} className='save'>Save</button>
                        <button onClick={closeModal} className='close'>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default App;
